import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom"
import { BASE_URL } from './Data/helper';

const Previous = () => {
    window.scrollTo(0, 0);
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`;
    const [FData, setFData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/api/getPrevious`);
                setFData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontFamily: 'Arial, sans-serif' }}>
            <div style={{  textAlign: 'center', color: 'black', padding: "30px 0" }}>
                <h2>Good Morning Shillong Teer Previous Result</h2>
            </div>
            <div style={{ overflowX: 'auto', width: '90%', marginTop: '20px' }}>
                <table style={{ borderCollapse: 'collapse', width: '100%', textAlign: 'left' }}>
                    <thead>
                        <tr style={{ backgroundColor: '#3FD5BA', color: 'black', border: '2px solid black' }}>
                            <th style={{ border: '1px solid #ddd', padding: '8px' }}>CITY</th>
                            <th style={{ border: '1px solid #ddd', padding: '8px' }}>DATE</th>
                            <th style={{ border: '1px solid #ddd', padding: '8px' }}>F/R</th>
                            <th style={{ border: '1px solid #ddd', padding: '8px' }}>S/R</th>
                        </tr>
                    </thead>
                    <tbody>
                        {FData.reverse().map((item, i) => (
                            <tr key={i} style={{ backgroundColor: i % 2 === 0 ? '#f2f2f2' : 'white' }}>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Shillong</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.Date}</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.FR}</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.SR}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                margin: '20px 0'
            }}>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '10px', width: '80%', margin: '20px auto' }}>
                    <img src="ad2.png" alt="Ad 1" style={{ width: '100%', height: 'auto' }} />
                    <img src="ad1.png" alt="Ad 2" style={{ width: '100%', height: 'auto' }} />
                    <img src="ad3.jpg" alt="Ad 3" style={{ width: '100%', height: 'auto' }} />
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(6, 1fr)', gap: '10px', width: '80%', margin: '20px auto' }}>
                    <Link to="/"><img src="teer-result.jpg" alt="Ad 4" style={{ width: '100%', height: 'auto' }} /></Link>
                    <Link to="#"><img src="teercounterSocial.jpg" alt="Ad 5" style={{ width: '100%', height: 'auto' }} /></Link>
                    <Link to="/dream"><img src="teer-dream-numbers.jpg" alt="Ad 6" style={{ width: '100%', height: 'auto' }} /></Link>
                    <Link to="/common"><img src="teer-common-numbers.jpg" alt="Ad 7" style={{ width: '100%', height: 'auto' }} /></Link>
                    <Link to="#"><img src="targetgame.jpg" alt="Ad 8" style={{ width: '100%', height: 'auto' }} /></Link>
                    <Link to="/previous"><img src="teer previous numbers1.jpg" alt="Ad 9" style={{ width: '100%', height: 'auto' }} /></Link>
                </div>
            </div>
        </div>
    )
}

export default Previous;
