import React from 'react'
import { Link } from "react-router-dom"
import { Cdata } from './Data/commonData.js'
const Common = () => {
    window.scrollTo(0, 0);
    console.log(Cdata)
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`;
    return (
        // <div style={{ display: 'flex', flexDirection: 'column' }}>
        //     <div style={{ background: 'linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%)', textAlign: 'center', color: 'white', padding: "30px 0px 50px 0px" }}>
        //         <h1>Common Numbers</h1>
        //         <h1 style={{ fontWeight: "lighter", fontSize: "30px" }}>(Date: {formattedDate})</h1>
        //     </div>
        //     <div className='contC'>
        //         <div className="i backi">#</div>
        //         <div className="i backi">Direct</div>
        //         <div className="i backi">House</div>
        //         <div className="i backi">Ending</div>

        //         {
        //             Cdata.map((item) => {
        //                 return (
        //                     <>
        //                             <div className="i">{item.id}</div>
        //                             <div className="i">{item.Direct.join(',')}</div>
        //                             <div className="i">{item.House}</div>
        //                             <div className="i">{item.Ending}</div>
        //                     </>
        //                 )
        //             })
        //         }

        //     </div>
        // </div>

        <div style={{ fontFamily: 'Arial, sans-serif', textAlign: 'center' }}>
            <h1 style={{ marginTop: '20px' }}>Common Number</h1>
            <p style={{ width: '80%', margin: '0 auto', lineHeight: '1.5' }}>
                Common numbers refer to those numbers that have previously emerged and are regarded as fortunate by players.
                These common numbers are purely based on certain calculations done using past results.
                There is no guarantee of the accuracy of these numbers.
            </p>
            <table style={{
                width: '80%',
                margin: '20px auto',
                borderCollapse: 'collapse',
                textAlign: 'center'
            }}>
                <thead>
                    <tr>
                        <th colSpan="3" style={{
                            // backgroundColor: '#a2c4c9',
                            padding: '10px',
                            // border: '1px solid #000'
                        }}>22/07/2024</th>
                    </tr>
                    <tr>
                        <th colSpan="3" style={{
                            backgroundColor: 'cream',
                            padding: '10px',
                            border: '1px solid #000'
                        }}>SHILLONG</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={{ backgroundColor: "#3FD5BA" }}>
                        <td style={{ border: '1px solid #000', padding: '10px' }}>Direct</td>
                        <td style={{ border: '1px solid #000', padding: '10px' }}>House</td>
                        <td style={{ border: '1px solid #000', padding: '10px' }}>Ending</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid #000', padding: '10px' }}>73,18</td>
                        <td style={{ border: '1px solid #000', padding: '10px' }}>9</td>
                        <td style={{ border: '1px solid #000', padding: '10px' }}>6</td>
                    </tr>
                    <tr style={{ backgroundColor: "#3FD5BA" }}>
                        <td style={{ border: '1px solid #000', padding: '10px' }}>Direct</td>
                        <td style={{ border: '1px solid #000', padding: '10px' }}>House</td>
                        <td style={{ border: '1px solid #000', padding: '10px' }}>Ending</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid #000', padding: '10px' }}>85</td>
                        <td style={{ border: '1px solid #000', padding: '10px' }}>3</td>
                        <td style={{ border: '1px solid #000', padding: '10px' }}>4</td>
                    </tr>
                </tbody>
            </table>
            <p style={{ width: '80%', margin: '0 auto', fontSize: '12px' }}>
                Disclaimer: These common numbers are purely based on certain calculations done using past results.
                There is no guarantee of the accuracy of these numbers.
            </p>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                margin: '20px 0'
            }}>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '10px', width: '80%', margin: '20px auto' }}>
                    <img src="ad2.png" alt="Ad 1" style={{ width: '100%', height: 'auto' }} />
                    <img src="ad1.png" alt="Ad 2" style={{ width: '100%', height: 'auto' }} />
                    <img src="ad3.jpg" alt="Ad 3" style={{ width: '100%', height: 'auto' }} />
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(6, 1fr)', gap: '10px', width: '80%', margin: '20px auto' }}>
                    <Link to="/"><img src="teer-result.jpg" alt="Ad 4" style={{ width: '100%', height: 'auto' }} /></Link>
                    <Link to="#"><img src="teercounterSocial.jpg" alt="Ad 5" style={{ width: '100%', height: 'auto' }} /></Link>
                    <Link to="/dream"><img src="teer-dream-numbers.jpg" alt="Ad 6" style={{ width: '100%', height: 'auto' }} /></Link>

                    <Link to="/common"><img src="teer-common-numbers.jpg" alt="Ad 6" style={{ width: '100%', height: 'auto' }} /></Link>
                    <Link to="#"><img src="targetgame.jpg" alt="Ad 6" style={{ width: '100%', height: 'auto' }} /></Link>
                    <Link to="/previous"><img src="teer previous numbers1.jpg" alt="Ad 6" style={{ width: '100%', height: 'auto' }} /></Link>
                </div>
            </div>
        </div>
    )
}

export default Common
