import React, { useState, useEffect } from 'react';
import '../../App.css';
import { Link } from 'react-router-dom'
import axios from 'axios';
import { BASE_URL } from './main/Data/helper';
import Footer from './main/Footer';
export default function Home() {
  const currentDate = new Date();
  const formattedDate = `${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`;
  const [FData, setFData] = useState({ FR:"", SR:"" }); // Corrected variable name to FData

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/getPreviousDay`);
        console.log(response.data)
        setFData(response.data); // Update FData with response data
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData(); // Call the fetchData function
  }, []);

  const images = [
    { src: 'teer-common-numbers.jpg', alt: 'CommonNumber', link: '/common' },
    { src: 'Sunday Morning Teer App Download.png', alt: 'Download App' },
    { src: 'teer-dream-numbers.jpg', alt: 'DreamNumber', link: "/dream" },
    { src: 'analytics.jpeg', alt: 'Analytics', },
    { src: 'targetgame.jpg', alt: 'Predict Target', },
    { src: 'teer previous numbers1.jpg', alt: 'PreviousResult', link: "/previous" },
    { src: 'TeerCalendar1.jpg', alt: 'Calendar', },
    { src: 'ReputedCounter1.jpg', alt: 'Target', },
    { src: 'OnlineShopping.jpg', alt: 'Online Shopping', },
  ];

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  return (
    <>
      <div className='main' style={{ margin: "0px 0px 80px 0px" }}>
        <div className='main_text'>

          {/* <img src="img2.jpeg" alt="" className='img2'/> */}
        </div>

        {/* <div className='home'></div> */}
        <h1 style={{
          textAlign: 'center', marginTop: "30px", fontSize: "25px",
          fontWeight: "600"
        }}
        >Good Morning Shillong Teer</h1>
        <h2 style={{ textAlign: 'center', marginTop: "5px", }}
        >{formattedDate}</h2>
        <div className='main-box'>

          {/* <div className='box'>
            <h1 >FR (Night: 10:15 PM)</h1>
            <h1 style={{ marginTop: '10px' }}>{FData.Nightdata == null ? "undefined" : FData.Nightdata.FR}</h1>
          </div>

          <div className='box'>
            <h1 >SR (Night: 11:15 PM)</h1>
            <h1 style={{ marginTop: '10px' }}>{FData.Nightdata == null ? "undefined" : FData.Nightdata.SR}</h1>
          </div> */}

          <table style={{ border: '1px solid black', width: '80%', textAlign: 'center', borderCollapse: 'collapse' }}>
            <thead>
              <tr style={{ backgroundColor: '#d3d3d3' }}>
                <th colSpan="2" style={{ border: '1px solid black', padding: '10px' }}>SHILLONG</th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ backgroundColor: '#40e0d0' }}>
                <td style={{ border: '1px solid black', padding: '10px' }}>F/R(10:15AM)</td>
                <td style={{ border: '1px solid black', padding: '10px' }}>S/R(11:15AM)</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid black', padding: '10px' }}>{FData == null ? "undefined" : FData.FR}</td>
                <td style={{ border: '1px solid black', padding: '10px' }}>{FData == null ? "undefined" : FData.SR}</td>
              </tr>
            </tbody>
          </table>


        </div>
        {/* <div className='button-box'>

          <Link to='/common' className='main_button' >
            <div  >Common Numbers</div>
          </Link>
          <Link to='/dream' className='main_button' >
            <div >Dream Numbers</div>
          </Link>
          <Link to='/previous' className='main_button' >
            <div >Previous Results</div>
          </Link>
        </div> */}

        <div style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gap: '10px',
          padding: '10px',
          marginBottom: "30px",

        }}>
          {images.map((image, index) => (
            <Link
              to={image.link}
              key={index}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              onClick={(image.alt !== 'CommonNumber' && image.alt !== 'DreamNumber' && image.alt !== 'PreviousResult') ? scrollToTop : undefined}
            >
              <img src={image.src} alt={image.alt} style={{ maxWidth: '100%', height: 'auto' }} />
            </Link>
          ))}
        </div>
      </div>

      {/* <Footer /> */}

    </>
  );
}
