import React, { useState, useEffect } from 'react';
import { useAlert } from 'react-alert';
import { useLogin } from './LoginProvider';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from './Data/helper';
import 'react-calendar/dist/Calendar.css';

const Dashboard = () => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`;
    const history = useHistory();
    const { isLogin } = useLogin();
    const alert = useAlert();
    const [formData, setFormData] = useState({ Date: formattedDate, FR: "", SR: "" });
    const [selectedField, setSelectedField] = useState(''); // State to track whether FR or SR is selected
    const [value, setValue] = useState(''); // State to track the input value

    const handleSubmit = (event) => {
        event.preventDefault();
        const updatedFormData = {
            ...formData,
            [selectedField]: value
        };
        console.log(updatedFormData);
        axios.put(`${BASE_URL}/api/addPrevious`, updatedFormData).then((data) => {
            alert.show("Result has been uploaded");
            setFormData({ ...formData,  FR: "", SR: "" });
            setSelectedField('');
            setValue('');
        }).catch(error => {
            console.log(error);
            alert.show("Something went wrong");
        });
    };

    const handleSelectChange = (event) => {
        setSelectedField(event.target.value);
        setValue('');
    };

    const handleInputChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <div>
            {
                isLogin === false ? history.push('/login') :
                    <form className='user_form content' onSubmit={handleSubmit}>
                        <div style={{ display: 'flex', justifyContent: "space-around" }} className='Twoinput'>
                            <div className='input'>
                                <label htmlFor='Date'>Date</label>
                                <input
                                    type="text"
                                    name='Date'
                                    value={formattedDate}
                                    readOnly
                                />
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: "space-around" }} className='Twoinput'>
                            <div className='input'>
                                <label htmlFor='selectField'>Select Field</label>
                                <select
                                    name='selectField'
                                    value={selectedField}
                                    onChange={handleSelectChange}
                                >
                                    <option value="">Select FR or SR</option>
                                    <option value="FR">FR</option>
                                    <option value="SR">SR</option>
                                </select>
                            </div>
                        </div>

                        {selectedField && (
                            <div style={{ display: 'flex', justifyContent: "space-around" }} className='Twoinput'>
                                <div className='input'>
                                    <label htmlFor='value'>{selectedField}</label>
                                    <input
                                        type="text"
                                        name='value'
                                        value={value}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                        )}

                        <div className='button' onClick={handleSubmit}>Submit</div>
                    </form>
            }
        </div>
    );
};

export default Dashboard;
