import React from 'react';
import { Ddata } from './Data/DreamData.js';
import { Link } from "react-router-dom"
const Dream = () => {
    window.scrollTo(0, 0);
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`;
    return (
        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: "10%" }}>
            <div style={{ textAlign: 'center', color: 'black', padding: "30px 0px 50px 0px" }}>
                <h1>Dream Numbers</h1>
                <h3 style={{ fontWeight: "lighter" }}>Below is a compilation of dream meanings.</h3>
            </div>

            <div style={{ width: '90%', margin: '20px auto', border: '1px solid #000', textAlign: 'center', fontFamily: 'Arial, sans-serif' }}>
                DREAM MEANING
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 3fr 3fr 1fr 1fr', backgroundColor: '#c2d6d6', padding: '10px 0' }}>
                    <div style={{ border: '1px solid #000', padding: '5px' }}>#</div>
                    <div style={{ border: '1px solid #000', padding: '5px' }}>Dream</div>
                    <div style={{ border: '1px solid #000', padding: '5px' }}>DIRECT</div>
                    <div style={{ border: '1px solid #000', padding: '5px' }}>House</div>
                    <div style={{ border: '1px solid #000', padding: '5px' }}>Ending</div>
                </div>
                {Ddata.map((item) => (
                    <div key={item.id} style={{ display: 'grid', gridTemplateColumns: '1fr 3fr 3fr 1fr 1fr', borderBottom: '1px solid #000' }}>
                        <div style={{ border: '1px solid #000', padding: '5px' }}>{item.id}</div>
                        <div style={{ border: '1px solid #000', padding: '5px' }}>{item.Dream}</div>
                        <div style={{ border: '1px solid #000', padding: '5px' }}>{item.Numbers.join(', ')}</div>
                        <div style={{ border: '1px solid #000', padding: '5px' }}>{item.House.join(', ')}</div>
                        <div style={{ border: '1px solid #000', padding: '5px' }}>{item.Ending.join(', ')}</div>
                    </div>
                ))}
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                margin: '20px 0'
            }}>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(6, 1fr)', gap: '10px', width: '80%', margin: '20px auto' }}>
                    <Link to="/"><img src="teer-result.jpg" alt="Ad 4" style={{ width: '100%', height: 'auto' }} /></Link>
                    <Link to="#"><img src="teercounterSocial.jpg" alt="Ad 5" style={{ width: '100%', height: 'auto' }} /></Link>
                    <Link to="/dream"><img src="teer-dream-numbers.jpg" alt="Ad 6" style={{ width: '100%', height: 'auto' }} /></Link>

                    <Link to="/common"><img src="teer-common-numbers.jpg" alt="Ad 6" style={{ width: '100%', height: 'auto' }} /></Link>
                    <Link to="#"><img src="targetgame.jpg" alt="Ad 6" style={{ width: '100%', height: 'auto' }} /></Link>
                    <Link to="/previous"><img src="teer previous numbers1.jpg" alt="Ad 6" style={{ width: '100%', height: 'auto' }} /></Link>
                </div>
            </div>
        </div>
    );
}

export default Dream;
