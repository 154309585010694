import React,{useState} from 'react'

const Footer = () => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`;
    const [FData, setFData] = useState({ Daydata: {}, Nightdata: {} }); // Corrected variable name to FData
    return (
        <div style={{
            backgroundColor: '#a2c4c9',
            color: 'black',
            textAlign: 'center',
            padding: '20px 10px',
            // position: 'fixed',
            left: 0,
            bottom: 0,
            width: '100%',
            marginTop:"30px"
          }}>
            <p>
              <a href="#" style={{ color: '#0000FF', textDecoration: 'none' }}>
                Download Good Morning Shillong Teer Android App - OTP NOT REQUIRED.
              </a><br />
              {formattedDate}. All Rights Reserved F/R(10:15AM) S/R(11:15AM).GoodMorningShillongTeer | Good Morning Shillong Teer<br />
              <a href="#" style={{ color: '#0000FF', textDecoration: 'none', margin: '0 10px' }}>Terms</a> |
              <a href="#" style={{ color: '#0000FF', textDecoration: 'none', margin: '0 10px' }}>Contact Us</a> |
              <a href="/privacy" style={{ color: '#0000FF', textDecoration: 'none', margin: '0 10px' }}>Privacy Policy</a>
            </p>
          </div>
    )
}

export default Footer
